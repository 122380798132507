<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-title class="d-flex align-center justify-space-between">
        <span v-t="'editor'" />
        <span>
          <v-btn v-t="'discard'" outlined color="secondary" small class="mr-3" @click="restoreDocumentTemplateData" />
          <v-btn
            color="primary"
            small
            :disabled="!areDataChanged || !documentTemplateDataValid || !localContentHtml.length"
            :loading="documentTemplateDataLoading"
            @click="handleDocumentTemplateSubmit"
            >{{ $t('save') }}</v-btn
          >
        </span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="documentTemplateDataValid" @submit.prevent="handleDocumentTemplateSubmit">
          <v-row>
            <v-col cols="12">
              <summer-note
                :key="rerenderSummernoteTrigger"
                :email-variables="documentVariablesSummernote"
                :custom-fields-variables="customFieldsVariables"
                :height="700"
                hide-link-and-image-button
                @ready="readySummerNote"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance, watch, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { mdiCheck, mdiSendOutline } from '@mdi/js'
import { required } from '@core/utils/validation'

import SummerNote from '@/plugins/summer-note/SummerNote.vue'

import {
  DOCUMENT_EMPLOYEES_VARIABLES,
  DOCUMENT_HOUSES_SHINE_VARIABLES,
  DOCUMENT_PROJECTS_SHINE_VARIABLES,
  DOCUMENT_CLIENTS_SHINE_VARIABLES,
  DOCUMENT_CANDIDATES_VARIABLES,
  parseCustomFieldsToVariables,
  mapNestedModuleCustomFieldsToVariables,
} from '@/plugins/summer-note/variables'
import documentTemplateUtils from '@/utils/templates/document/documentTemplateUtils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

const mammoth = require('mammoth')

export default {
  components: {
    SummerNote,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const { MODULE_EMPLOYEES, MODULE_HOUSES, MODULE_PROJECTS, MODULE_CLIENTS, MODULE_CANDIDATES } =
      documentTemplateUtils()

    const { updateDocumentTemplate } = useActions('templates', ['updateDocumentTemplate'])
    const { getCandidateCustomFields } = useActions('company', ['getCandidateCustomFields'])
    const { getHouseCustomFields, getProjectCustomFields, getClientCustomFields } = useActions('staff', [
      'getHouseCustomFields',
      'getProjectCustomFields',
      'getClientCustomFields',
    ])
    const { documentTemplateData } = useState('templates', ['documentTemplateData'])

    const documentTemplateDataLoading = ref(false)
    const documentTemplateDataValid = ref(false)

    const localContentHtml = ref('')

    localContentHtml.value = documentTemplateData.value.contentHtml

    const editor = ref(null)
    const rerenderSummernoteTrigger = ref(false)

    const documentVariablesSummernote = ref([])
    const customFields = ref([])
    const customFieldsVariables = ref([])

    const setDocumentVariables = async () => {
      if (documentTemplateData.value.module === MODULE_EMPLOYEES) {
        customFields.value = await getCandidateCustomFields()
        documentVariablesSummernote.value = DOCUMENT_EMPLOYEES_VARIABLES(vm)

        const houseCustomFields = await getHouseCustomFields()
        const projectCustomFields = await getProjectCustomFields()
        const clientCustomFields = await getClientCustomFields()

        customFieldsVariables.value = [
          ...parseCustomFieldsToVariables(customFields.value, documentTemplateData.value.module),
          ...mapNestedModuleCustomFieldsToVariables(houseCustomFields, MODULE_EMPLOYEES, MODULE_HOUSES, vm),
          ...mapNestedModuleCustomFieldsToVariables(projectCustomFields, MODULE_EMPLOYEES, MODULE_PROJECTS, vm),
          ...mapNestedModuleCustomFieldsToVariables(clientCustomFields, MODULE_EMPLOYEES, MODULE_CLIENTS, vm),
        ]
      } else if (documentTemplateData.value.module === MODULE_HOUSES) {
        customFields.value = await getHouseCustomFields()
        documentVariablesSummernote.value = DOCUMENT_HOUSES_SHINE_VARIABLES(vm)
        customFieldsVariables.value = parseCustomFieldsToVariables(
          customFields.value,
          documentTemplateData.value.module,
        )
      } else if (documentTemplateData.value.module === MODULE_PROJECTS) {
        customFields.value = await getProjectCustomFields()
        documentVariablesSummernote.value = DOCUMENT_PROJECTS_SHINE_VARIABLES(vm)
        customFieldsVariables.value = parseCustomFieldsToVariables(
          customFields.value,
          documentTemplateData.value.module,
        )
      } else if (documentTemplateData.value.module === MODULE_CLIENTS) {
        customFields.value = await getClientCustomFields()
        documentVariablesSummernote.value = DOCUMENT_CLIENTS_SHINE_VARIABLES(vm)
        customFieldsVariables.value = parseCustomFieldsToVariables(
          customFields.value,
          documentTemplateData.value.module,
        )
      } else if (documentTemplateData.value.module === MODULE_CANDIDATES) {
        customFields.value = await getCandidateCustomFields()
        documentVariablesSummernote.value = DOCUMENT_CANDIDATES_VARIABLES(vm)
        customFieldsVariables.value = parseCustomFieldsToVariables(
          customFields.value,
          documentTemplateData.value.module,
        )
      }
    }
    onMounted(async () => {
      await setDocumentVariables()
      editor.value.summernote('destroy')
      rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
    })

    const readySummerNote = (initializedEditor) => {
      editor.value = initializedEditor
      editor.value.summernote('code', localContentHtml.value)
      editor.value.$on('change', (content) => {
        localContentHtml.value = content
      })
    }

    const areDataChanged = computed(() => {
      return documentTemplateData.value.contentHtml !== localContentHtml.value
    })

    const handleDocumentTemplateSubmit = async () => {
      if (!areDataChanged) return

      if (!documentTemplateDataValid.value) return

      documentTemplateDataLoading.value = true

      await updateDocumentTemplate({
        id: documentTemplateData.value.id,
        payload: {
          contentHtml: localContentHtml.value,
          module: documentTemplateData.value.module,
        },
      })

      localContentHtml.value = documentTemplateData.value.contentHtml

      documentTemplateDataLoading.value = false
    }

    const restoreDocumentTemplateData = () => {
      localContentHtml.value = documentTemplateData.value.contentHtml
    }

    watch(
      () => documentTemplateData.value.module,
      async (newValue, oldValue) => {
        if (newValue === MODULE_EMPLOYEES && oldValue !== MODULE_EMPLOYEES) {
          documentVariablesSummernote.value = DOCUMENT_EMPLOYEES_VARIABLES(vm)
          customFields.value = await getCandidateCustomFields()
          const houseCustomFields = await getHouseCustomFields()
          const projectCustomFields = await getProjectCustomFields()
          const clientCustomFields = await getClientCustomFields()

          customFieldsVariables.value = [
            ...parseCustomFieldsToVariables(customFields.value, newValue),
            ...mapNestedModuleCustomFieldsToVariables(houseCustomFields, MODULE_EMPLOYEES, MODULE_HOUSES, vm),
            ...mapNestedModuleCustomFieldsToVariables(projectCustomFields, MODULE_EMPLOYEES, MODULE_PROJECTS, vm),
            ...mapNestedModuleCustomFieldsToVariables(clientCustomFields, MODULE_EMPLOYEES, MODULE_CLIENTS, vm),
          ]

          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else if (newValue === MODULE_HOUSES && oldValue !== MODULE_HOUSES) {
          documentVariablesSummernote.value = DOCUMENT_HOUSES_SHINE_VARIABLES(vm)
          customFields.value = await getHouseCustomFields()
          customFieldsVariables.value = parseCustomFieldsToVariables(customFields.value, newValue)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else if (newValue === MODULE_PROJECTS && oldValue !== MODULE_PROJECTS) {
          documentVariablesSummernote.value = DOCUMENT_PROJECTS_SHINE_VARIABLES(vm)
          customFields.value = await getProjectCustomFields()
          customFieldsVariables.value = parseCustomFieldsToVariables(customFields.value, newValue)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else if (newValue === MODULE_CLIENTS && oldValue !== MODULE_CLIENTS) {
          documentVariablesSummernote.value = DOCUMENT_CLIENTS_SHINE_VARIABLES(vm)
          customFields.value = await getClientCustomFields()
          customFieldsVariables.value = parseCustomFieldsToVariables(customFields.value, newValue)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else if (newValue === MODULE_CANDIDATES && oldValue !== MODULE_CANDIDATES) {
          documentVariablesSummernote.value = DOCUMENT_CANDIDATES_VARIABLES(vm)
          customFieldsVariables.value = parseCustomFieldsToVariables(customFields.value, newValue)
          customFields.value = await getCandidateCustomFields()
          customFieldsVariables.value = parseCustomFieldsToVariables(customFields.value, newValue)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        }
      },
    )

    return {
      readySummerNote,
      handleDocumentTemplateSubmit,
      restoreDocumentTemplateData,

      areDataChanged,

      localContentHtml,
      documentTemplateDataLoading,
      documentTemplateDataValid,
      documentVariablesSummernote,
      rerenderSummernoteTrigger,
      customFieldsVariables,

      validators: {
        required,
      },

      icons: {
        mdiCheck,
        mdiSendOutline,
      },
    }
  },
}
</script>
