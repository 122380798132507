<template>
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title v-t="'edit'" class="justify-center text-h5" />

      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="documentTemplateDataLocal.name"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
                :label="$t('name')"
                :placeholder="$t('name')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="documentTemplateDataLocal.module"
                :label="$t('selectModule')"
                :placeholder="$t('selectModule')"
                :items="defaultChoices.moduleChoices"
                outlined
                dense
                hide-details
                :rules="[validators.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'
import _ from 'lodash'
import documentTemplateUtils from '@/utils/templates/document/documentTemplateUtils'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    documentTemplateData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { defaultChoices } = documentTemplateUtils()
    const valid = ref(true)
    const loading = ref(false)

    const bioEditForm = ref(null)

    const documentTemplateDataLocal = ref({})
    documentTemplateDataLocal.value = _.cloneDeep(props.documentTemplateData)

    const { updateDocumentTemplate } = useActions('templates', ['updateDocumentTemplate'])

    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      const { success } = await updateDocumentTemplate({
        id: documentTemplateDataLocal.value.id,
        payload: {
          name: documentTemplateDataLocal.value.name,
          module: documentTemplateDataLocal.value.module,
        },
      })
      loading.value = false

      if (success) {
        emit('update:is-bio-dialog-open', false)
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        documentTemplateDataLocal.value = _.cloneDeep(props.documentTemplateData)
      },
    )

    const areDataChanged = computed(() => {
      return (
        documentTemplateDataLocal.value.name !== props.documentTemplateData.name ||
        documentTemplateDataLocal.value.module !== props.documentTemplateData.module
      )
    })

    return {
      handleFormSubmit,

      defaultChoices,
      valid,
      loading,
      bioEditForm,
      documentTemplateDataLocal,
      areDataChanged,

      validators: {
        required,
      },
    }
  },
}
</script>
