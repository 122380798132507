<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            documentTemplateName: documentTemplateData.name,
          }"
        />
        <document-bio-panel />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <document-tab-editor v-if="documentTemplateData.id" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from '@vue/composition-api'
import { mdiAccountCancelOutline } from '@mdi/js'
import { useActions, useMutations, useState } from 'vuex-composition-helpers'
import router from '@/router'
// eslint-disable-next-line object-curly-newline
import DocumentBioPanel from './document-bio-panel/DocumentBioPanel.vue'

// import ProjectTabInternalNotes from './project-tab-internal-notes/ProjectTabInternalNotes.vue'
import DocumentTabEditor from './document-tab-editor/DocumentTabEditor.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'

export default {
  components: {
    BreadCrumbs,
    DocumentBioPanel,
    DocumentTabEditor,
  },
  setup() {
    const { getDocumentTemplate } = useActions('templates', ['getDocumentTemplate'])
    const { documentTemplateData } = useState('templates', ['documentTemplateData'])
    const { SET_DOCUMENT_TEMPLATE_DATA } = useMutations('templates', ['SET_DOCUMENT_TEMPLATE_DATA'])

    const fetchDocumentTemplateData = async () => {
      await getDocumentTemplate(router.currentRoute.params.id)
    }

    onMounted(async () => {
      await fetchDocumentTemplateData()
    })

    onUnmounted(() => {
      SET_DOCUMENT_TEMPLATE_DATA({})
    })

    return {
      fetchDocumentTemplateData,

      documentTemplateData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
